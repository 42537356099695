import styled from "styled-components";

export const ItemTitle = styled.p`
  font-size: var(--textS);
  font-weight: normal;
  line-height: var(--lineHeightS);
  padding: 0px;
  margin: 0px;
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: 1128px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: var(--textL);
    line-height: var(--lineHeightL);
  }
`;

export const ItemListWrapper = styled.li`
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacingXs) var(--spacingS);
  list-style: none;
  gap: var(--spacingS);

  svg {
    fill: var(--primary800);
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--gray200);
  }

  @media screen and (min-width: 1128px) {
    border-bottom: 1px solid var(--gray200);
    padding: var(--spacingS) var(--spacingM);
    gap: var(--spacingM);
    min-height: 80px;

    svg {
      height: 32px;
      width: 32px;
    }

    &:first-of-type {
      border-top: 1px solid var(--gray200);
    }
  }
`;

export default ItemListWrapper;
