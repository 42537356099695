import { renderLineBreaks } from "../../../utils";
import { StarFilled } from "@carbon/icons-react";
import { ItemListWrapper, ItemTitle } from "./styles";

export const CheckListItem = ({ text }) => {
  return (
    <ItemListWrapper>
      <StarFilled size="24" />
      <ItemTitle>{renderLineBreaks(text)}</ItemTitle>
    </ItemListWrapper>
  );
};

export default CheckListItem;
